import React, { useContext, useEffect } from 'react'
import { graphql, Link } from 'gatsby'

import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { CAL_BUY_LINK, IS_FOREIGN_COUNTRY } from 'src/utils/constants'
import PropTypes from 'prop-types'
import fitsYourSpace from 'src/gifs/introducing-cal-max.gif'
import StoreContext from '../context/StoreContext'
import FAQs from '../components/FAQs'
import SEO from '../components/seo'
import CountDownTimer from '../components/CountDownTimer'
import { CenteredContent, BuyNowLink } from '../components/Hero/styles'
import { ContentContainer, HideOverflow } from '../utils/styles/global-styles'
import Analytics from '../utils/Analytics'
import CalendarPress from '../components/CalendarPress'
import CalendarMatrix from '../components/CalendarMatrix'
import CalendarGuarantee from '../components/CalendarGuarantee'
import RedesignCalendarTestimonials from '../components/RedesignCalendarTestimonials'
import CalendarNewFeatures from '../components/CalendarNewFeatures'
import Counter from '../components/Counter'
import CalendarHero from '../components/CalendarHero'
import Quiz from '../components/Quiz'

import {
  MaxContainer,
  CtaContainer,
  BuyNowLinkCTA,
  Flexbox,
  HideOnDesktop,
  HideOnMobile,
} from '../components/HomepageProducts/styles'
import { CalTitle, Text } from '../components/CalendarNewFeatures/styles'

const CalendarPage = ({ data: { calendarOgImage } }) => {
  const { t } = useTranslation('calendar')
  const { updateBanner } = useContext(StoreContext)

  useEffect(() => {
    Analytics.track('Viewed Calendar Landing Page')

    let url = window.location.href

    if (url.endsWith('/')) {
      url = url.slice(0, -1)
    }

    const urlParams = new URLSearchParams(new URL(url).search)
    const utmTerm = urlParams.get('utm_term')

    if (utmTerm && utmTerm.toUpperCase() === 'LD30') {
      const promoCode = 'LD30'
      const channel = 'PR'
      const amount = '30'
      updateBanner(promoCode, channel, amount)
    }
  }, [updateBanner])

  return (
    <>
      <SEO
        title={t('Smart Touchscreen Family Calendar and Organizer | Skylight Calendar')}
        description={t(
          "Skylight Calendar is a smart, touchscreen wall calendar that displays your family's important events and chores."
        )}
        imagePath={calendarOgImage.childImageSharp.gatsbyImageData.images.fallback.src}
        path="/calendar/"
      />
      <Helmet>
        <meta charset="UTF-8" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
        />
      </Helmet>
      <CalendarHero />
      {!IS_FOREIGN_COUNTRY && <Quiz hideImage calendar />}
      {!IS_FOREIGN_COUNTRY && (
        <MaxContainer>
          <Flexbox>
            <CalTitle>Introducing Calendar Max ✨</CalTitle>
            <Text>
              Simplify your busy household and experience all the beloved features of our 10" and
              15" Calendars unified in one expansive, high-resolution display, tailor-made for
              dynamic family life.
            </Text>
            <CtaContainer>
              <Link to="/products/skylight-calendar-max/">
                <BuyNowLinkCTA>Buy Now</BuyNowLinkCTA>
              </Link>
            </CtaContainer>
          </Flexbox>
          <img src={fitsYourSpace} alt="Skylight Calendar turns into a screensaver" width="100%" />
        </MaxContainer>
      )}
      <CalendarNewFeatures />
      <CalendarGuarantee first />
      <HideOverflow>
        <RedesignCalendarTestimonials />
      </HideOverflow>
      <CalendarPress />
      <CalendarGuarantee />
      <CalendarMatrix />
      <FAQs page="calendar" />
      <ContentContainer>
        <CenteredContent>
          <BuyNowLink to={CAL_BUY_LINK}>
            <Trans>Buy Now</Trans>
          </BuyNowLink>
        </CenteredContent>
      </ContentContainer>
    </>
  )
}

CalendarPage.propTypes = {
  data: PropTypes.shape({
    calendarOgImage: PropTypes.object.isRequired,
  }).isRequired,
}

export default CalendarPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "calendar"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    calendarOgImage: file(relativePath: { eq: "cal-social-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`
