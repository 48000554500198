import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  position: relative;
`
export const HeroImageContainer = styled.div`
  width: 100%;
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;
  display: block;
  margin-bottom: -16px;
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`
export const MobileImageContainer = styled.div`
  width: 100%;
  border-radius: 0px 0px 16px 16px;
  display: none;
  @media (max-width: ${breakpoints.l}px) {
    display: block;
    margin-bottom: -16px;
  }
`

export const ContentContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  width: 1200px;
  text-align: center;
  transform: translate(-50%, 0%);
  @media (max-width: ${breakpoints.xl}px) {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.l}px) {
    max-width: 90%;
    margin: auto;
  }
`

export const Headline = styled.p`
  margin: auto;
  padding: 50px 0px 0px;
  display: flex;
  justify-content: center;
  @media (max-width: 1350px) {
    padding: 50px 0px 0px;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding: 150px 0px 0px;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 80px 0px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 40px 0px 0px;
  }
  @media (max-width: 375px) {
    padding: 20px 0px 0px;
  }
`
export const Title = styled.h1`
  color: black;
  font-family: 'FilsonProBold' !important;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.72px;
  margin: 15px auto 24px;
  width: 100%;
  @media (max-width: 1350px) {
    font-size: 48px;
    margin: 8px auto;
  }
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 40px;
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 48px;
    margin: 24px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 32px;
    line-height: 111.3%;
    letter-spacing: -0.384px;
    margin: 16px auto;
  }
  @media (max-width: 430px) {
    font-size: 30px;
  }
  @media (max-width: 400px) {
    font-size: 28px;
    margin: 10px auto 8px;
  }
  @media (max-width: 375px) {
    font-size: 26px;
  }
  @media (max-width: 350px) {
    font-size: 24px;
  }
`
export const Text = styled.p`
  color: black;
  font-family: 'FilsonPro' !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.288px;
  max-width: 800px;
  margin: 24px auto 48px;
  @media (max-width: 1350px) {
    font-size: 22px;
    margin: 16px auto 24px;
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 24px;
    margin: 0px auto 48px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.192px;
    margin: 0px auto 24px;
  }
  @media (max-width: 350px) {
    font-size: 15px;
  }
`
export const StarContainer = styled.div`
  margin: auto;
  padding: 50px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1350px) {
    padding: 50px 0px 0px;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding: 150px 0px 0px;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 80px 0px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 40px 0px 0px;
  }
  @media (max-width: 375px) {
    padding: 20px 0px 0px;
  }
`
